import React from 'react';
import { get } from 'lodash';

export const CarouselCard = ({ data }) => {
    const title = get(data, 'title');
    const subtitle = get(data, 'subtitle');
    const copy = get(data, 'copy');
    const image = get(data, 'sliderImage.fields.file.url')
    return (
        <>
            <div className="d-lg-none d-xl-none">
                <div className="card border-0" style={{ minHeight: '800px' }}>
                    <div className="card-body p-0 carousel-background ">
                        <div className="row no-gutters d-flex align-items-center">
                            <div className="col-md-8">
                                <img src={image} alt={get(data, 'sliderImage.fields.title')} className="img-fluid" />
                            </div>
                            <div className="col-md-4 text-white" style={{ color: 'white', padding: '45px 24px' }}>
                                <div className="d-flex flex-column">
                                    <h2 className="mb-5 title-text" style={{ color: 'white' }}>{title}</h2>
                                    <h4 className="mb-5" style={{ color: 'white' }}>{subtitle}</h4>
                                    <p className="m-0 sm-semi-font">{copy}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-lg-block">
                <div className="card border-0 carousel-background">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <div className="card-body d-flex flex-column h-100 card-pad">
                                <h2 className="mb-5 title-text" style={{ color: 'white' }}>{title}</h2>
                                <h4 className="mb-5 story-text" style={{ color: 'white' }}>{subtitle}</h4>
                                <p className="m-0 sm-semi-font">{copy}</p>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-center align-items-center">
                            <img src={image} className="card-img img-fluid" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
