import React from 'react';
import logo from '../assets/logos/nav-logo.png';

export const MainNav = () => (
    <nav className="navbar navigation fixed-top">
        <div className="d-flex justify-content-between align-items-center w-100">
            <a href="https://www.nba.com/clippers/"><img src="https://www.nba.com/resources/static/team/v2/clippers/img/CE_icon_overspary_logo.png" className="nav-logo" /></a>
            <div className="d-flex flex-row">
                <a href="#presale" className="mb-0 p-5 text-uppercase nav-font">sign up</a>
                <a href="#story" className="mb-0 p-5 text-uppercase nav-font">story</a>
                <a href="#mark" className="mb-0 p-5 text-uppercase nav-font">make your mark</a>
            </div>
        </div>
    </nav>
);
