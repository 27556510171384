import React from 'react';
import Modal from 'react-modal';
import { Main } from './containers/main';
import './App.css';

Modal.setAppElement('#root')


const App = () => (
  <>
    <Main />
  </>
)

export default App;
