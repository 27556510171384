import React from 'react';
import { get } from 'lodash';

export const MobileMakeYourMark = ({ data }) => {
    const title = get(data, 'title');
    const copy = get(data, 'copy');
    const media = get(data, 'mediaContent');
    const images = media && media.map(mediaContent => mediaContent.fields.file);
    const stepList = get(data, 'listContent.fields.itemDetails');
    const listItems = stepList && stepList.map(listItem => listItem.fields)
    return (
        <div className="makeyourmark-background">
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <h4 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h4>
                        <p className="mt-4 sm-semi-font">{copy}</p>
                    </div>
                    <div className="col-md-8">
                        {images && listItems
                            && images.map((image, index) =>
                                <div className="d-flex flex-column mt-5">
                                    <h4 className="text-uppercase step-text" style={{ color: 'white' }}>0 {index + 1}. {listItems[index].item}</h4>
                                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                                        <div>
                                            <img src={image.url} className="img-fluid" />
                                        </div>
                                        <div>
                                            <p className="mb-0 semi-font">{listItems[index].details}</p>
                                        </div>
                                    </div>
                                    {listItems[index].button
                                        && <a id={listItems[index].buttonDownload ? 'ce-download-template-m' : null} href={listItems[index].buttonDownload ? get(listItems[index].buttonDownload, 'fields.file.url') : `${listItems[index].linkUrl}`} target="_blank" download={get(listItems[index].buttonDownload, 'fields.file.url')}>
                                            <button className="ce-button w-100 button-text text-uppercase">{listItems[index].button}</button>
                                        </a>
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
