import React, { useState } from 'react';
import { get } from 'lodash';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'

export const MobileVideo = ({ videoContent }) => {
    const [showModal, modal] = useState(false);
    const title = get(videoContent, 'title');
    const copy = get(videoContent, 'copy');
    const media = get(videoContent, 'mediaContent');
    const cta = get(videoContent, 'buttonText');
    const thumbnail = get(videoContent, 'mainImage.fields.file.url')
    const video = media && media.map(mediaContent => mediaContent.fields.file.url);

    const onOpenModal = () => {
        modal(true);
    }

    const onCloseModal = () => {
        modal(false);
    }

    const customStyle = {
        overlay: {
            zIndex: '2000',
            backgroundColor: '#000000e0',
        },
        content: {
            position: 'relative',
            background: 'transparent',
            top: 0,
            left: 0,
            border: '0px'
        }
    }
    return (
        <>
            <div style={{ background: 'black', position: 'relative' }}>
                <div className="d-flex flex-row flex-wrap">
                    <div className="h-100 w-100" style={{ position: 'relative' }}>
                        <img src={thumbnail} alt="Cartoon" className="img-fluid" />
                        <div id="ce-video-click-m" style={{
                            position: 'absolute', zIndex: '2', top: '45%', right: '45%', bottom: 'auto', left: 'auto'
                        }}>
                            <FontAwesomeIcon id="ce-video-click-m" icon={faPlayCircle} size="4x" onClick={() => onOpenModal()} style={{ color: 'white' }} />
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="p-4">
                            <h4 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h4>
                            <p className="mt-4 sm-semi-font" style={{ color: 'white' }}>{copy}</p>
                            <button id="ce-video-click-m" className="ce-button w-100 mt-4 button-text text-uppercase" onClick={() => onOpenModal()}>{cta}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={onCloseModal}
                style={customStyle}
            >
                <span onClick={onCloseModal} style={{ position: 'absolute', top: '0', right: '25px', color: 'white', fontSize: '50px', zIndex: '2001' }}>&times;</span>
                {video
                    &&
                    <div className="videoWrapper">
                        <video controls autoPlay poster={thumbnail}>
                            <source src="https://www.nba.com/resources/static/team/v2/clippers/files/CITY-EDITION-MAIN-FEATURE-16x9.mp4" type="video/mp4" />
                            <source src="https://www.nba.com/resources/static/team/v2/clippers/files/CITY-EDITION-MAIN-FEATURE-16x9.mp4" type="video/webm" />
                        </video>
                    </div>
                }
            </Modal>
        </>
    )
}