import React from 'react';
import { get } from 'lodash';
import { MobileWorkshop } from './mobile-workshop';

export const Workshop = ({ workshop }) => {
    const title = get(workshop, 'title');
    const copy = get(workshop, 'copy');
    const cta = get(workshop, 'buttonText');
    const image = get(workshop, 'mainImage.fields.file.url');
    const link = get(workshop, 'linkUrl');
    return (
        <>
            <div className="d-lg-none d-xl-none">
                <MobileWorkshop workshop={workshop} />
            </div>
            <div className="d-none d-lg-block">
                <div className="workshop-background text-white" style={{ color: 'white' }}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-flex flex-column">
                                <h3 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h3>
                                <p className="mt-4 sm-semi-font">{copy}</p>
                                <a id="ce-workshop-reg" href={link} target="_blank">
                                    <button className="ce-button mt-4 button-text text-uppercase">{cta}</button>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <img src={image} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}