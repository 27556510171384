import React, { useState } from 'react';
import { get } from 'lodash';
import Modal from 'react-modal'
import { MobileVideo } from './mobile-video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'

export const Video = ({ videoContent }) => {
    const [showModal, modal] = useState(false);
    const title = get(videoContent, 'title');
    const copy = get(videoContent, 'copy');
    const media = get(videoContent, 'mediaContent');
    const cta = get(videoContent, 'buttonText');
    const thumbnail = get(videoContent, 'mainImage.fields.file.url')
    const video = media && media.map(mediaContent => mediaContent.fields.file.url);

    const onOpenModal = () => {
        modal(true);
    }

    const onCloseModal = () => {
        modal(false);
    }

    const customStyle = {
        overlay: {
            zIndex: '2000',
            backgroundColor: '#000000e0',
        },
        content: {
            position: 'relative',
            background: 'transparent',
            top: 0,
            left: 0,
            border: '0px'
        }
    }

    return (
        <>
            <div className="d-lg-none d-xl-none">
                <MobileVideo videoContent={videoContent} />
            </div>
            <div className="d-none d-lg-block">
                <div className="h-100">
                    <div className="fullscreen-bg text-white" style={{ color: 'white' }}>
                        <div className="col-md-4 d-flex align-items-center" style={{ zIndex: '1', position: 'absolute', height: '100%', padding: '120px 60px' }}>
                            <div className="p-5">
                                <div className="h-100 d-flex flex-column">
                                    <h4 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h4>
                                    <p className="mt-4 sm-semi-font">{copy}</p>
                                    {/* <div onClick={() => onOpenModal()}>
                                        <button className="ce-button">{cta}</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div id="ce-video-click" className="h-100 w-100 d-flex justify-content-center align-items-center" style={{ position: 'absolute', zIndex: '2' }}>
                            <FontAwesomeIcon id="ce-video-click" icon={faPlayCircle} size="8x" onClick={() => onOpenModal()} />
                        </div>

                        {video
                            && <video loop muted autoPlay poster={thumbnail} className="fullscreen-bg__video">
                                <source src={video} type="video/mp4" />
                                <source src={video} type="video/webm" />
                            </video>
                        }


                    </div>
                    <Modal
                        isOpen={showModal}
                        onRequestClose={onCloseModal}
                        style={customStyle}
                    >
                        <span onClick={onCloseModal} style={{ position: 'absolute', top: '0', right: '25px', color: 'white', fontSize: '50px', zIndex: '2001' }}>&times;</span>
                        {video
                            &&
                            <div className="videoWrapper">
                                <video controls autoPlay poster={thumbnail}>
                                    <source src="https://www.nba.com/resources/static/team/v2/clippers/files/CITY-EDITION-MAIN-FEATURE-16x9.mp4" type="video/mp4" />
                                    <source src="https://www.nba.com/resources/static/team/v2/clippers/files/CITY-EDITION-MAIN-FEATURE-16x9.mp4" type="video/webm" />
                                </video>
                            </div>
                        }
                    </Modal>
                </div>
            </div>
        </>
    );
}






