import React from 'react';
import { get } from 'lodash';

export const MobileMerch = ({ merch }) => {
    const title = get(merch, 'title');
    const subtitle = get(merch, 'subtitle');
    const cta = get(merch, 'buttonText');
    const image = get(merch, 'mainImage.fields.file.url');
    const link = get(merch, 'linkUrl');
    return (
        <div className="d-flex flex-column merch-pad" style={{ background: '#3C3C3C' }}>
            <div>
                <img src={image} className="img-fluid" alt="collection" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <h4 className="title-text" style={{ color: 'white' }}>{title}</h4>
                <p className="mb-4 story-text" style={{ color: 'white' }}>{subtitle}</p>
            </div>
            <a id="ce-merch-m" href={link} target="_blank">
                <button className="ce-button w-100 button-text text-uppercase">{cta}</button>
            </a>
        </div>
    )
}