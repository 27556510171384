import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntries } from '../ducks/entries/actions';
import { getAllEntries } from '../ducks/entries/selectors';
import { Nav } from './nav';
import { Header } from '../components/header';
import { Presale } from '../components/presale';
import { Story } from '../components/story';
import { Carousel } from '../components/carousel';
// import { Deposit } from '../components/deposit';
import { Merch } from '../components/merch';
import { Video } from '../components/video';
import { Workshop } from '../components/workshop';
import { RoyyalDog } from '../components/royyal-dog';
import { MakeYourMark } from '../components/make-your-mark';

export const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEntries());
  }, []);

  const components = useSelector(getAllEntries);
  const header =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Header');
  const presale =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Presale Sign Up');
  const story =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Story Section');
  const carousel =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Carousel Section');
  const deposit =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Deposit');
  const merch =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Merch Section');
  const videoSection =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Cartoon Video Section');
  const cartoonWorkshop =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Cartoon Workshop');
  const royyalDog =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Royyal Dog');
  const designChallenge =
    components &&
    components
      .map((component) => component.fields)
      .find((section) => section.sectionName === 'Design Challenge');

  return (
    <div>
      <Nav />
      {components && (
        <>
          <Header header={header} />
          <Presale presale={presale} />
          <Story story={story} />
          <Carousel carousel={carousel} />
          {/* <Deposit deposit={deposit} /> */}
          <Merch merch={merch} />
          <Video videoContent={videoSection} />
          <Workshop workshop={cartoonWorkshop} />
          <RoyyalDog royyalDog={royyalDog} />
          <MakeYourMark data={designChallenge} />
        </>
      )}
    </div>
  );
};
