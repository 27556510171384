import React from 'react';
import logo from '../assets/logos/nav-logo.png';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle'

export const MobileNav = () => (
    <nav className="navbar navigation navbar-dark fixed-top">
            <a href="https://www.nba.com/clippers/"><img src="https://www.nba.com/resources/static/team/v2/clippers/img/CE_icon_overspary_logo.png" className="nav-logo" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
                <li>
                    <a className="nav-link text-uppercase nav-font" href="#presale" style={{ color: 'white' }}>Sign Up</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-uppercase nav-font" href="#story" style={{ color: 'white' }}>Story</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-uppercase nav-font" href="#mark" style={{ color: 'white' }}>Make Your Mark</a>
                </li>
            </ul>
        </div>
    </nav>
);

