import React from 'react';
import { get } from 'lodash'

export const MobileWorkshop = ({ workshop }) => {
    const title = get(workshop, 'title');
    const copy = get(workshop, 'copy');
    const cta = get(workshop, 'buttonText');
    const image = get(workshop, 'mainImage.fields.file.url');
    const link = get(workshop, 'linkUrl');
    return (
        <div className="workshop-background text-white">
            <div className="row">
                <div className="col-md-8">
                    <h3 className="text-uppercase title-text mb-4" style={{ color: 'white' }}>{title}</h3>
                    <img src={image} className="img-fluid" />
                </div>
                <div className="col-md-4">
                    <div className="d-flex flex-column">
                        <p className="mb-0 mt-4 sm-semi-font">{copy}</p>
                        <a id="ce-workshop-reg-m" href={link} target="_blank">
                            <button className="ce-button w-100 mt-4 button-text text-uppercase">{cta}</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}