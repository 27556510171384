import React from 'react';
import { get } from 'lodash';
import { MobileHeader } from '../components/mobile-header';

export const Header = ({ header }) => {
    const title = get(header, 'title');
    const subtitle = get(header, 'subtitle');
    const copy = get(header, 'copy');
    const link = get(header, 'linkUrl');
    const cta = get(header, 'buttonText');
    return (
        <>
            <div className="d-lg-none d-xl-none">
                <MobileHeader header={header} />
            </div>
            <div className="d-none d-lg-block">
                <div className="header-background text-white">
                    <div className="inner-header h-100">
                        <div className="p-5 h-100">
                            <div className="d-flex flex-column justify-content-start align-items-end h-100">
                                <div className="d-flex justify-content-start flex-column">
                                    <h1 className="xl-header" style={{ color: 'white' }}>{title}</h1>
                                    <h1 className="xl-header" style={{ color: 'white' }}>{copy}</h1>
                                    <h3 className="lg-header" style={{ color: 'white' }}>{subtitle}</h3>
                                    {/* <div style={{ paddingTop: '60px' }}>
                                        <a href={link} target="_blank">
                                            <button
                                                className="button-text text-uppercase"
                                                style={{ background: 'transparent linear-gradient(180deg, #FC153B 0%, #C8102E 100%) 0% 0% no-repeat padding-box', color: 'white', padding: '28px 68px', border: '1px solid red' }}>
                                                {cta}
                                            </button>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
