import React from 'react';
import { get } from 'lodash'
import { MobileStory } from './mobile-story';

export const Story = ({ story }) => {
    const copy = get(story, 'copy')
    return (
        <>
            <div id="story">
                <img id="story-header" src="https://www.nba.com/resources/static/team/v2/clippers/img/1920x602-CE-story-header-201118.jpg" />
            </div>
            <div className="d-lg-none d-xl-none">
                <MobileStory story={story} />
            </div>
            <div className="d-none d-lg-block">
                <div className="story-background text-white d-flex justify-content-center">
                    <div className="inner-story">
                        <div className="p-5">
                            <p className="story-text" style={{ color: '#CCCCCC' }}>{copy}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}