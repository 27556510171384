import React from 'react';
import { get } from 'lodash';

export const MobileRoyyal = ({ royyalDog }) => {
    const title = get(royyalDog, 'title');
    const copy = get(royyalDog, 'copy');
    const image = get(royyalDog, 'mainImage.fields.file.url');
    const mediaContent = get(royyalDog, 'mediaContent');
    const images = mediaContent && mediaContent.map(media => media.fields).map(img => img.file.url);
    return (
        <div d-flex flex-row flex-wrap royyaldog-inner>
            <div className="desktop-pad">
                <div>
                    <h4 className="text-uppercase title-text">{title}</h4>
                    <img src={image} className="img-fluid" />
                    <p className="mt-3 sm-semi-font">{copy}</p>
                </div>
                <div>
                    {images &&
                        <div className="d-flex flex-column flex-wrap">
                            <div className="d-flex flex-row align-items-center">
                                <div>
                                    <img className="img-fluid" src={images[0]} />
                                </div>
                                <div className="d-flex flex-column">
                                    <img className="img-fluid mb-3 ml-2" src={images[1]} />
                                    <img className="img-fluid ml-2" src={images[2]} />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="mr-2 mt-3">
                                    <img className="img-fluid" src={images[3]} />
                                </div>
                                <div className="ml-2 mt-3">
                                    <img className="img-fluid" src={images[4]} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
