import React from 'react';
import { get } from 'lodash';
import logo from '../assets/logos/clippers-fanshop.png'

export const Presale = ({ presale }) => {
    const title = get(presale, 'title');
    const subtitle = get(presale, 'subtitle');
    const image = get(presale, 'mainImage.fields.file.url');
    return (
        <div className="presale-background text-white" style={{ color: 'white' }} id="presale">
            <div className="row d-flex align-items-center">
                <div className="col-lg-6">
                    <img src={image} className="img-fluid" />
                </div>
                <div className="col-lg-6">
                    <div className="d-flex flex-column">
                        <div>
                            <img src="https://www.nba.com/resources/static/team/v2/clippers/img/Clippers_Fan_Shop_LOGO_wht.png" style={{ height: '100px' }} alt="logo" />
                        </div>
                        <h4 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h4>
                        <p className="semi-font" style={{ color: 'white' }}>{subtitle}</p>
                        <div>
                            <div data-form-block-id="0d198d76-0381-eb11-a812-000d3a31cf76"></div>

                            <div id="dCkRGdwr8gqQciszfKGy6WSHUeDaZ8ywLqTALLwNc3OM"></div>

                            <div class="d365-mkt-config" style={{ display: 'none' }} data-website-id="CkRGdwr8gqQciszfKGy6WSHUeDaZ8ywLqTALLwNc3OM" data-hostname="aa8d20baa12c40e281518e9e92b636ad.svc.dynamics.com"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}