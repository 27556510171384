import React from 'react';
import { get } from 'lodash';
import { MobileRoyyal } from './mobile-royyal';

export const RoyyalDog = ({ royyalDog }) => {
    const title = get(royyalDog, 'title');
    const copy = get(royyalDog, 'copy');
    const image = get(royyalDog, 'mainImage.fields.file.url');
    const mediaContent = get(royyalDog, 'mediaContent');
    const images = mediaContent && mediaContent.map(media => media.fields).map(img => img.file.url);
    return (
        <>
            <div className="d-lg-none d-xl-none">
                <MobileRoyyal royyalDog={royyalDog} />
            </div>
            <div className="d-none d-lg-block">
                <div className="royyaldog-background">
                    <div className="p-5 row royyaldog-inner d-flex align-items-center">
                        <div className="col-md-4">
                            <h4 className="text-uppercase title-text">{title}</h4>
                            <img src={image} className="img-fluid mt-4" />
                            <p className="mt-4 sm-semi-font">{copy}</p>
                        </div>
                        <div className="col-md-8">
                            {images &&
                                <div className="d-flex flex-column flex-wrap">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <img className="img-fluid" src={images[0]} />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <img className="img-fluid mb-3 ml-2" src={images[1]} />
                                            <img className="img-fluid ml-2" src={images[2]} />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="mr-2 mt-3">
                                            <img className="img-fluid" src={images[3]} />
                                        </div>
                                        <div className="ml-2 mt-3">
                                            <img className="img-fluid" src={images[4]} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}