import React from 'react';
import { get } from 'lodash';
import { MobileMakeYourMark } from './mobile-make-your-mark';

export const MakeYourMark = ({ data }) => {
    const title = get(data, 'title');
    const copy = get(data, 'copy');
    const media = get(data, 'mediaContent');
    const images = media && media.map(mediaContent => mediaContent.fields.file);
    const stepList = get(data, 'listContent.fields.itemDetails');
    const listItems = stepList && stepList.map(listItem => listItem.fields)
    // const buttonContent = listItems && listItems.buttonDownload
    console.log(listItems)
    return (
        <div id="mark">
            <div className="d-lg-none d-xl-none">
                <MobileMakeYourMark data={data} />
            </div>
            <div className="d-none d-lg-block">
                <div className="makeyourmark-background text-white" style={{ color: 'white' }}>
                    <div className="row p-4">
                        <div className="col-md-4">
                            <h4 className="text-uppercase title-text" style={{ color: 'white' }}>{title}</h4>
                            <p className="mt-4 sm-semi-font">{copy}</p>
                        </div>
                        <div className="col-md-8 d-flex flex-row flex-wrap">
                            {
                                images && listItems
                                && images.map((image, index) =>
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <h4 className="text-uppercase step-text" style={{ color: 'white' }}>0{index + 1}.</h4>
                                            <div style={{ height: '96px' }}>
                                                <h4 className="text-uppercase step-text" style={{ color: 'white' }}>{listItems[index].item}</h4>
                                            </div>
                                            <img src={image.url} className="img-fluid" />
                                            <p className="mb-0 semi-font" style={{ minHeight: '100px' }}>{listItems[index].details}</p>
                                            {listItems[index].button
                                                && <a id={listItems[index].buttonDownload ? 'ce-download-template' : null} href={listItems[index].buttonDownload ? get(listItems[index].buttonDownload, 'fields.file.url') : `${listItems[index].linkUrl}`} target="_blank" download={get(listItems[index].buttonDownload, 'fields.file.url')}>
                                                    <button className="ce-button mt-5 button-text text-uppercase">{listItems[index].button}</button>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}