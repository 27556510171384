import React from 'react';
import { MainNav } from '../components/main-nav';
import { MobileNav } from '../components/mobile-nav';

export const Nav = () => (
    <>
        <div className="d-lg-none d-xl-none d-md-none">
            <MobileNav />
        </div>
        <div className="d-none d-md-block">
            <MainNav />
        </div>
    </>
)
