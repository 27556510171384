import React from 'react';
import { get } from 'lodash';
import { MobileMerch } from './mobile-merch';

export const Merch = ({ merch }) => {
    const title = get(merch, 'title');
    const subtitle = get(merch, 'subtitle');
    const cta = get(merch, 'buttonText');
    const image = get(merch, 'mainImage.fields.file.url');
    const link = get(merch, 'linkUrl');
    const media = get(merch, 'mediaContent');
    const images = media && media.map(mediaContent => mediaContent.fields.file);
    return (
        <>
            <div className="d-lg-none d-xl-none">
                <MobileMerch merch={merch} />
            </div>
            <div className="d-none d-lg-block">
                <div className="card border-0">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <img src={image} className="img-fluid h-100" />
                        </div>
                        <div className="col-md-8" style={{ background: '#3C3C3C' }}>
                            <div className="merch-pad">
                                <div className="card-body ">
                                    <div className="d-flex flex-column">
                                        <h4 className="title-text text-uppercase mb-0" style={{ color: 'white' }}>
                                            {title}
                                        </h4>
                                        <p className="mb-5 story-text" style={{ color: 'white' }}>{subtitle}</p>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mb-5">
                                        {images
                                            && images.map(image =>
                                                <div>
                                                    <img src={image.url} className="merch-img" alt="collection" />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <a id="ce-merch" href={link} target="_blank">
                                        <button className="ce-button button-text text-uppercase">{cta}</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
