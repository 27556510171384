import React from 'react';
import { get } from 'lodash';

export const MobileStory = ({ story }) => {
    const copy = get(story, 'copy');
    return (
        <div className="story-background text-white d-flex justify-content-center">
            <p className="story-text" style={{ color: '#CCCCCC' }}>{copy}</p>
        </div>
    )
}
