import React, { useRef } from 'react';
import { get } from 'lodash';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CarouselCard } from './carousel-card';

const PrevArrow = ({ onClick }) => <div className="carousel-left" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} size="1x" />
</div>

const NextArrow = ({ onClick }) => <div className="carousel-right" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} size="1x" />
</div>

export const Carousel = ({ carousel }) => {
    const carouselArray = get(carousel, 'slider.fields.sliderContents');
    const slider = useRef(null);
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 0,
        className: 'slides',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1565,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1060,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    swipeToSlide: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    swipeToSlide: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    swipeToSlide: true
                }
            }
        ]
    };
    return (
        <div>
            {!!carouselArray
                && <Slider ref={slider} {...settings}>
                    {carouselArray.map(data => <CarouselCard key={data.fields.sliderTitle} data={data.fields} />)}
                </Slider>
            }

        </div>
    )
} 